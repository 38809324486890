import request from "@/utils/request";
import BdApi from '@/const/apiUrl/bd';
import method from "@/const/request/requestMethod";
import contentType from "@/const/request/requestHeader";
import requestType from "@/const/request/requestType";


//BD员工信息列表
export function bdList(searchData) {
    return request({
        url: BdApi.bdList,
        method: method.GET,
        data: searchData
    })
}
//新建BD(员工绑定)
export function bdAdd(addData) {
    return request({
        url: BdApi.bdAdd,
        method: method.POST,
        data: addData,
        requestType: requestType.ADD,
        headers: {
          'Content-Type': contentType.formURL
        }
    })
}

//BD变更工作状态
export function bdEdit(editData) {
    return request({
        url: BdApi.bdEdit,
        method: method.GET,
        data: editData,
        requestType: requestType.EDIT,

    })
}
//根据ID删除BD人员
export function bdDelete(deleId) {
    return request({
        url: BdApi.bdDelete,
        method: method.GET,
        data: deleId,
        requestType: requestType.DELETE
    })
}
//BD备注编辑
export function bdEditor(editData) {
    return request({
        url: BdApi.bdEditor,
        method: method.POST,
        data: editData,
        requestType: requestType.EDIT,
        headers: {
            'Content-Type': contentType.formURL
          }
    })
}

export function bdGetInfo(getData) {
    return request({
        url: BdApi.bdInfo,
        method: method.GET,
        data: getData
    })
}
//BD员工维护信息列表
export function bdGetMaintain(getData) {
    return request({
        url: BdApi.bdMaintain,
        method: method.GET,
        data: getData
    })
}
//BD员工维护商品列表
export function bdGetGoods(getData) {
    return request({
        url: BdApi.bdGoods,
        method: method.GET,
        data: getData
    })
}
//BD员工维护店铺列表
export function bdGetStore(getData) {
    return request({
        url: BdApi.bdStore,
        method: method.GET,
        data: getData
    })
}
// BD归属人下拉列表
export function bdOptions(getData) {
    return request({
        url: BdApi.bdOptions,
        method: method.GET,
        data: getData
    })
}