const goodsApi = {
    //获取商品列表
    goodsList: '/goods/list',
    //删除商品
    goodsDelete: '/goods/delete',
    //新增商品
    goodsAdd: '/goods/add',
    //编辑商品
    goodsEdit: '/goods/edit',
    //编辑商品单个属性
    getIdEdit: '/goods/editSingle',
    //根据id获取详情
    goodsIdWord: '/goods/getById',
    // 上架下架
    goodsEnabled: '/goods/enabled',
    // 导出
    goodsDownLoad: '/goods/downloadExcel',
    // 导入
    goodsUpLoad: '/goods/uploadGoodsExcel',
    // 商品单位
    goodsnUit: '/goods/getUnit',
    // 根据页签获取数据条数
    goodsTabStatus:'/goods/sumGoodsTabStatus',

    //商品分类列表
    goodsCategoryList: '/goodsCategory/list',
    //获取分类详情
    goodsCategoryetaDiled: '/goodsCategory/detail',
    //删除商品分类
    goodsCategoryetaDelete: '/goodsCategory/delete',
    //新建商品分类
    goodsCategoryetaAdd: '/goodsCategory/add',
    //更新商品分类
    goodssCategoryetaEdit: '/goodsCategory/edit',
    //商品分类列表(树状)
    goodsCategoryTreeList: '/goodsCategory/treeList',
    //隐藏显示
    goodsCategoryChangeStatus:'/goodsCategory/changeStatus',
}


export default goodsApi