import request from "@/utils/request";
import goodsApi from '@/const/apiUrl/goods';
import method from "@/const/request/requestMethod";
import contentType from "@/const/request/requestHeader";
import requestType from "@/const/request/requestType";


// 获取列表
export function goodsList(params) {
    return request({
        url: goodsApi.goodsList,
        method: method.POST,
        data: params,
        headers: {
            'Content-Type': contentType.formURL
        }
    })
}
// 删除
export function goodsDelete(params) {
    return request({
        url: goodsApi.goodsDelete,
        method: method.POST,
        data: params,
        requestType: requestType.DELETE,
        headers: {
            'Content-Type': contentType.formURL
        }
    })
}

//新增
export function goodsAdd(params) {
    return request({
        url: goodsApi.goodsAdd,
        method: method.POST,
        data: params,
        requestType: requestType.ADD,

    })
}

//编辑
export function goodsEdit(params) {
    return request({
        url: goodsApi.goodsEdit,
        method: method.POST,
        data: params,
        requestType: requestType.EDIT
    })
}

//编辑单个属性
export function goodsIdEdit(params) {
    return request({
        url: goodsApi.getIdEdit,
        method: method.POST,
        data: params,
        requestType: requestType.EDIT
    })
}


// 根据id获取
export function goodsIdWord(params) {
    return request({
        url: goodsApi.goodsIdWord,
        method: method.GET,
        data: params
    })
}

// 开关
export function goodsEnabled(params) {
    return request({
        url: goodsApi.goodsEnabled,
        method: method.POST,
        data: params,
    })
}

//导出
export function goodsDownLoad() {
    return request({
        url: goodsApi.goodsDownLoad,
        method: method.GET,
        responseType: 'blob', // 设置响应数据类型
        headers: {
            'Content-Type': contentType.formURL
        }
    })
}


//导入

export function goodsUpLoad(params) {
    return request({
        url: goodsApi.goodsUpLoad,
        method: method.POST,
        data: params,
    })
}

// 根据id获取
export function goodsTabStatus(params) {
    return request({
        url: goodsApi.goodsTabStatus,
        method: method.GET,
        data: params
    })
}


//商品分类列表
export function goodsCategoryList(params) {
    return request({
        url: goodsApi.goodsCategoryList,
        method: method.POST,
        data: params,
        headers: {
            'Content-Type': contentType.formURL
        }
    })
}
//删除商品分类
export function goodsCategoryetaDelete(params) {
    return request({
        url: `${goodsApi.goodsCategoryetaDelete}/${params}`,
        method: method.POST,
        headers: {
            'Content-Type': contentType.formURL
        }
    })
}

//商品分类详情
export function goodsCategoryetaDiled(params) {
    return request({
        url:`${goodsApi.goodsCategoryetaDiled}/${params}`,
        method: method.GET,
    })
}
//编辑商品分类
export function goodsCategoryetaEdit(params) {
    return request({
        url: goodsApi.goodssCategoryetaEdit,
        method: method.POST,
        data: params,
    })
}
// 新增商品分类
export function goodsCategoryetaAdd(params) {
    return request({
        url: goodsApi.goodsCategoryetaAdd,
        method: method.POST,
        data: params,
    })
}
// 商品分类列表(树状)
export function goodsCategoryTreeList(params) {
    return request({
        url: goodsApi.goodsCategoryTreeList,
        method: method.POST,
        data: params,
    })
}

// 显示隐藏
export function goodsCategoryChangeStatus(params) {
    return request({
        url: goodsApi.goodsCategoryChangeStatus,
        method: method.GET,
        data: params,
    })
}

// 商品单位

export function goodsnUit(params) {
    return request({
        url: goodsApi.goodsnUit,
        method: method.GET,
        data: params,
    })
}


