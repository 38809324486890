const BdApi = {
    //BD员工信息列表
    bdList: '/bd/list',
    //新建BD
    bdAdd: '/bd/add',
    //BD变更工作状态
    bdEdit: '/bd/changeStatus',
    //删除
    bdDelete: '/bd/delete',
    // 编辑
    bdEditor:'/bd/edit',
    // 获取BD详情
    bdInfo:'/bd/getById',
    // BD员工信息列表
    bdMaintain:'/bd/maintain/list',
    //BD员工维护店铺列表
    bdStore:'/bd/maintain/store/list',
    //BD员工维护商品列表
    bdGoods:"/bd/maintain/goods/list",
    //BD归属人下拉列表
    bdOptions:"/bd/options"
}


export default BdApi
