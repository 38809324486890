/**
 * 请求类型
 */

const requestType = {
  ADD: '添加',
  EDIT: '修改',
  DELETE: '删除'
}

export default requestType
